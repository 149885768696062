<template>
	<div class="car-table-card">
		<!-- <el-divider></el-divider> -->
		<div class="video">
			<div class="video_frames" v-for="(channel, index) in checkList" :key="index" v-show="channel.isShow == true">
				<video-player
					:stream="false"
					:channel="false"
					:autoPlay="false"
					:ref="`vplayer${channel.id}`"
					class="vplayer"
					:params="getParams(channel.id)"
					:vtitle="getTitle(channel.id)"
				></video-player>
			</div>
		</div>
		<div class="chanels">
			<el-checkbox-group v-model="selectChanel" @change="changeChanel">
				<el-checkbox v-for="(one, index) in checkList" :key="index" :label="one.id">{{ one.name }}</el-checkbox>
			</el-checkbox-group>
		</div>
		<!-- <el-divider></el-divider> -->
	</div>
</template>

<script>
import VideoPlayer from "@/components/pages/admin/videoPlayer/flvPlayer";
import { deviceType } from "@/common/js/wordbook.js";
export default {
	props: {
		params: {
			type: Object,
			default: function () {
				return {};
			}
		},
		showDialog: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		/* params: {
			handler: function (val) {
				console.log(val, "------watch");
				this.init({ ...val });
			},
			immediate: true,
			deep: true
		}, */
		showDialog: {
			async handler(val) {
				if (val) {
					this.initData();
					this.init({ ...this.params });
				} else {
					this.resetData();
				}
			},
			immediate: true
		}
	},
	mounted() {},
	methods: {
		resetData() {
			this.selectChanel = [];
			this.checkList = [];
		},
		initData() {
			this.selectChanel = [1, 2, 3, 4];//, 5, 6, 7, 8只需要4个通道
			this.checkList = [
				{
					name: "通道1",
					id: 1,
					isShow: true
				},
				{
					name: "通道2",
					id: 2,
					isShow: true
				},
				{
					name: "通道3",
					id: 3,
					isShow: true
				},
				{
					name: "通道4",
					id: 4,
					isShow: true
				}
			];
			// ,
			// 	{
			// 		name: "通道5",
			// 		id: 5,
			// 		isShow: true
			// 	},
			// 	{
			// 		name: "通道6",
			// 		id: 6,
			// 		isShow: true
			// 	},
			// 	{
			// 		name: "通道7",
			// 		id: 7,
			// 		isShow: true
			// 	},
			// 	{
			// 		name: "通道8",
			// 		id: 8,
			// 		isShow: true
			// 	}
		},
		init(params) {
			// 查询车辆设备
			// this.deviceList = deviceList;
			// this.deviceId = this.deviceList[0].deviceId;
			this.deviceId = params.deviceId;
			this.vehicleNo = params.vheicleNo;
			this.$nextTick(() => {
				// 第一次不需要断开重连
				for (let i = 1; i < 9; i++) {
					console.log(this.deviceId);
					this.$refs[`vplayer${i}`][0].changeDeviceId(this.deviceId);
				}
			});

			this.setTypeList(deviceType());
		},
		setTypeList(typeList) {
			this.typeList = typeList;
		},
		changeDeviceId() {
			this.$refs.vplayer && this.$refs.vplayer.changeDeviceId(this.deviceId);
		},
		changeChannel() {
			this.$refs.vplayer && this.$refs.vplayer.changeChannelId(this.channelId);
		},
		changeChanel(val) {
			let channels = [1, 2, 3, 4, 5, 6, 7, 8];
			let noselect = "";
			noselect = val.concat(channels).filter(function (v, i, arr) {
				return arr.indexOf(v) === arr.lastIndexOf(v);
			});
			if (noselect.length > 0) {
				noselect.forEach(e => {
					for (let i = 0; i < this.checkList.length; i++) {
						if (e == this.checkList[i].id) {
							this.checkList[i].isShow = false;
						}
					}
				});
			}
			if (val.length > 0) {
				val.forEach(item => {
					for (let i = 0; i < this.checkList.length; i++) {
						if (item == this.checkList[i].id) {
							this.checkList[i].isShow = true;
						}
					}
				});
			}
		},
		changeTalk(uuid, openFunc) {
			if (!this.isTalking) {
				openFunc();
			}
		},
		getDeviceName(val) {
			const type = this.typeList.find(v => v.value == val);
			return type ? type.label : "未知";
		}
	},
	destroyed() {
		this.channelId = 1;
		this.stream = 0;
		this.deviceId = "";
	},
	beforeDestroy() {
		this.$refs.vplayer && this.$refs.vplayer.destroy();
	},
	data() {
		return {
			selectChanel: [],
			checkList: [],
			vehicleNo: "",
			deviceList: [],
			deviceId: "",
			channelId: 1,
			stream: 1,
			streamList: [
				{
					label: "主码流",
					value: 0
				},
				{
					label: "子码流",
					value: 1
				}
			],

			typeList: [],
			allCancel: false
		};
	},
	computed: {
		getParams() {
			return channel => {
				return {
					clientId: this.deviceId,
					channelId: channel
				};
			};
		},
		getTitle() {
			return channel => this.vehicleNo + "-" + this.deviceId + "-" + channel;
		}
	},
	components: {
		VideoPlayer
	}
};
</script>
<style lang="scss" scoped>
.car-table-card {
	// padding: 35px;
	width: 100%;
	.video {
		overflow-y: auto;
		// max-height: 600px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.video_frames {
			width: 49%;
			min-height: 40vh;
			height: auto;
			border: 1px solid #ccc;
			margin-top: 10px;
		}
	}
	.chanels {
		max-width: 800px;
		margin-top: 10px;
	}
}
</style>
